import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import PageSubHeader   from "../components/pageSubHeader";
import CaseTestimonials from "../components/caseTestimonials";
import CaseSwitcher from "../components/caseSwitcher";
import CaseHeroImage from "../components/caseHeroImage";
import BentleyImage0 from "../img/cases/bentley/image_0.png";
import BentleyImage from "../img/cases/bentley/preview.png";
import CaseImagesBlock from "../components/caseImagesBlock";
import BentleyImage21 from "../img/cases/bentley/image_2_1.png";
import BentleyImage22 from "../img/cases/bentley/image_2_2.png";
const BentleyCase = () => {

    return (
        <>
            <Helmet>
                <title>BENTLEY BLACK BOX Dark Matter Experience | Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    titleText={"BENTLEY BLACK BOX Dark Matter Experience"}
                    subTitleText={"A thrilling black box experience unveiling Bentley's latest model"}
                />
                <CaseTestimonials testimonials={['Spatial design', '3D cinematic', 'LED lighting show']}/>
                <CaseHeroImage image={BentleyImage0} imageALT="BENTLEY BLACK BOX Dark Matter Experience"/>
                <PageSubHeader text="The objective was to create an extraordinary artwork experience for the reveal of a new Bentley model, integrating the themes of ‘adrenaline rush' and 'black box mystery’."/>
                <PageSubHeader text="Creating a transformational journey by blending technology, luxury, and mystique to unveil the launch of the new car in a way that's never been done before."/>
                <CaseImagesBlock images={[BentleyImage21, BentleyImage22]}/>
                <PageSubHeader text="This event was a journey through a world, an alchemical fusion of light and darkness, designed to involve the guests as part of an exclusive circle witnessing the arrival of the new untamed Bentley. "/>
                <PageSubHeader text="More than just an immersive LED lighting performance in a 3D spatial gallery, Bentley wanted to engrave their brand values in people’s minds. Positioning themselves as a pioneer of power and innovation in the automotive industry."/>
                <PageHeader
                    titleText={"Business objective:"}
                    subTitleText={"The goal was to target a younger customer base and leave guests in awe, offering a sense of exclusivity and a thrilling narrative that unfolds through visual solutions and sensory engagement."}
                />
                <CaseSwitcher previousLink="/works/prism/" previousName="Prism: The Art of Light" nextLink="/works/mothers-day-queen/" nextName="Mother’s Day Queen"/>

            </section>
        </>
    )
}

export default BentleyCase;
